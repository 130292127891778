import React, { useState } from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { Grid } from '@clariness/grid';
import { InputField } from '@clariness/input-field';
import { Dropdown } from '@clariness/dropdown';
import { Text } from '@clariness/text';
import { Store } from 'store';
import {
  Schemas,
  useFormValidation,
  validateRequiredField,
} from 'utils/validation';
import { useMultiStep } from 'components/MultiStep';
import * as _ from 'lodash-es';
import { SiteEmployeeTitle } from 'global/siteEmployeeTitle';
import { Services } from 'services';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';

export const AccountDetailsForm = () => {
  const intl = useIntl();

  const { nextStep } = useMultiStep();

  const { user } = Store.useUser();

  const [title, setTitle] = useState({
    label: user?.title ? SiteEmployeeTitle[user.title].label : '',
    value: user?.title,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useFormValidation({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
    },
  });

  const { updateCurrentSiteEmployee, isLoading } =
    Services.SiteEmployees.useUpdateCurrentSiteEmployee({
      onSuccess: (data) => {
        captureEventV2(
          Events.InvestigatorService.InvestigatorChangeAccountDetails({
            InvestigatorID: user?.id ?? '',
          })
        );
        reset(data);
      },
    });

  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage
          id="is.account_details.heading"
          defaultMessage="Account details"
        />
      </AccountPageLayout.Heading>

      <AccountPageLayout.Paragraph>
        <FormattedMessage
          id="is.account_details.main_paragraph"
          defaultMessage="You can update your personal data and change your password here."
        />
      </AccountPageLayout.Paragraph>

      <AccountPageLayout.Form
        onSubmit={handleSubmit((data) =>
          updateCurrentSiteEmployee({ ...data, title: title.value })
        )}
      >
        <Grid
          sx={{
            columnGap: '54px',
            rowGap: '34px',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Dropdown
            value={title.value}
            onChange={(newTitle: any) =>
              setTitle({ label: newTitle, value: newTitle })
            }
            placeholder={intl.formatMessage({
              id: 'is.account_details.title_select_placeholder',
              defaultMessage: 'Title',
            })}
            data-test-id="account-title-select"
          >
            {_.map(SiteEmployeeTitle, (siteEmployeeTitle) => (
              <Dropdown.Option
                key={siteEmployeeTitle.value}
                value={siteEmployeeTitle.value}
              >
                {siteEmployeeTitle.label}
              </Dropdown.Option>
            ))}
          </Dropdown>

          <InputField
            required
            {...register('firstName', validateRequiredField(intl))}
            placeholder={intl.formatMessage({
              id: 'is.account_details.first_name_input_placeholder',
              defaultMessage: 'First name',
            })}
            error={errors?.firstName?.message}
            data-test-id="account-first-name-input"
          />

          <InputField
            required
            {...register('lastName', validateRequiredField(intl))}
            placeholder={intl.formatMessage({
              id: 'is.account_details.last_name_input_placeholder',
              defaultMessage: 'Last name',
            })}
            error={errors?.lastName?.message}
            data-test-id="account-last-name-input"
          />

          <InputField
            {...register('email', Schemas.email(intl))}
            required
            type="email"
            placeholder={intl.formatMessage({
              id: 'is.account_details.email_input_placeholder',
              defaultMessage: 'Email address',
            })}
            error={errors?.email?.message}
            data-test-id="account-email-input"
          />

          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '10px',
            }}
          >
            <InputField
              placeholder="****************"
              disabled
              data-test-id="account-password-input"
            />

            <Button
              variant="tertiary"
              size="medium"
              onClick={nextStep}
              data-test-id="account-change-password-button"
            >
              <FormattedMessage
                id="is.account_details.change_password_button"
                defaultMessage="Change password"
              />
            </Button>
          </Flex>

          <InputField
            {...register('phone')}
            placeholder={intl.formatMessage({
              id: 'is.account_details.phone_number_input_placeholder',
              defaultMessage: 'Phone number',
            })}
            data-test-id="account-phone-input"
          />
        </Grid>

        <Flex
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Text variant="small">
            <FormattedMessage
              id="is.account_details.mandatory_fields_text"
              defaultMessage="*Mandatory fields"
            />
          </Text>

          <Button
            type="submit"
            disabled={!isDirty && title?.value === user?.title}
            loading={isLoading}
            data-test-id="account-details-save-button"
          >
            <FormattedMessage
              id="is.account_details.save_button"
              defaultMessage="Save"
            />
          </Button>
        </Flex>
      </AccountPageLayout.Form>
    </AccountPageLayout>
  );
};
