export const SupportedCountries = [
  'en-GB',
  'en-US',
  'es-US',
  'de-DE',
  'de-AT',
  'pl-PL',
  'de-CH',
  'it-CH',
  'fr-CH',
  'fr-CA',
  'en-CA',
  'en-AU',
  'fr-BE',
  'nl-BE',
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'et-EE',
  'fi-FI',
  'sv-FI',
  'hu-HU',
  'en-IE',
  'ja-JP',
  'lv-LV',
  'lt-LT',
  'sr-RS',
  'xh',
  'en-ZA',
  'af-ZA',
  'es-ES',
  'sv-SE',
  'fr-FR',
  'hi-IN',
  'en-IN',
  'nl-NL',
  'sk-SK',
  'ru-LT',
  'es-AR',
  'pt-BR',
  'it-IT',
  'en-NZ',
  'el-GR',
  'es-MX',
  'zh-TW',
  'zh-HK',
  'es-CL',
  'th-TH',
  'ms-MY',
  'zh-MY',
  'en-SG',
  'es-CO',
  'no-NO',
  'fi-SE',
  'st-ZA',
  'zu-ZA',
  'en-GH',
  'en-NG',
  'sl-SI'
];

export const fallbackLocale = SupportedCountries[0];
